<template>
    <div>
        <b-dropdown v-if="events.length" aria-role="list" class="p-4">
            <template #trigger="{ active }">
                <b-button
                    label="Outras ações"
                    type="is-dark-outlined"
                    :icon-right="active ? 'menu-up' : 'menu-down'" />
            </template>

            <b-dropdown-item v-for="event in events" :key="event.id" :disabled="event.disabled" :value="event.value" @click="handleEvents(event.action)" aria-role="listitem">
                <span>{{ event.label }}</span>
            </b-dropdown-item>
        </b-dropdown>
        <b-table
        :data="data"
        :bordered="false"
        :striped="true"
        :narrowed="false"
        :hoverable="true"
        :loading="false"
        :focusable="false"
        :mobile-cards="true"
        :paginated="true"
        :checked-rows.sync="getCheckedRows"
        checkable
        scrollable
        >
            <b-table-column v-for="column in columns" :key="column.field"  :numeric="column.numeric" :field="column.field" :label="column.label" v-slot="props">
                <is-active-tag v-if="column.ActiveTag" :active="props.row[column.field]" />
                <dropdown-tag v-else-if="column.dropdown" :isDeleting="false">
                    <dropdown-item-tag v-for="item in column.dropdownItems" :key="item.id" @click="handleEvents(item.action, props.row)">{{ item.label }}</dropdown-item-tag>
                </dropdown-tag>
                <span v-else>{{ props.row[column.field] }}</span>
            </b-table-column>
        </b-table>
    </div>
</template>
<script> 
import DropdownItemTag from './DropdownItemTag.vue'
import DropdownTag from '../commons/DropdownTag.vue'
import IsActiveTag from './IsActiveTag.vue'

export default {
  name: 'ZTable',
  components: {
    IsActiveTag,
    DropdownItemTag,
    DropdownTag
  },
  props: {
    checkedRows: {
      type: Array,
      required: false
    },
    data: {
      type: Array,
      required: true
    },
    events: {
      type: Array,
      required: false
    },
    columns: {
      type: Array,
      required: true
    }
  },
  computed: {
    getCheckedRows: {
      get () {
        return this.checkedRows
      },
      set (value) {
        this.$emit('update:checkedRows', value)
      }
    }
  },
  methods: {
    handleEvents (event, data) {
      this.$emit(event, data)
    }
  }
}
</script>