<template>
  <b-modal :active="isComponentModalActive" @close="closeModal">
    <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Atualização em massa</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important">
            <b-field label="Selecione um campo a ser atualizado">
              <b-select
                v-model="fieldSelected"
                placeholder="Selecione um campo"
                expanded
              >
                <option
                  v-for="(field, index) in fieldsTitle"
                  :key="index"
                  :value="field"
                >
                  {{ field }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Insira o Valor">
              <b-input
                type="text"
                v-model="value"
                placeholder="Valor"
                v-if="fieldSelected && fieldType === 'text'"
                required
              >
              </b-input>
              <b-input
                type="number"
                v-model="value"
                placeholder="Valor"
                v-if="fieldSelected && fieldType === 'number'"
                required
              >
              </b-input>
              <b-select
                v-model="value"
                placeholder="Valor"
                v-if="fieldSelected && fieldType === 'select'"
                expanded
              >
                <option
                  v-for="(field, index) in ['Verdadeiro', 'Falso']"
                  :key="index"
                  :value="field"
                >
                  {{ field }}
                </option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button class="button is-primary" :disabled="isDisabled" @click="save">
              Gravar
            </button>
          </footer>
        </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalUpdateField',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    fields: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      fieldSelected: null,
      value: ''
    }
  },
  computed: {
    isDisabled () {
      return !this.fieldSelected || !this.value
    },
    fieldType () {
      switch (this.fields.find(f => f.label === this.fieldSelected).type) {
        case 'boolean':
          return 'select'
        case 'number':
          return 'number'
        default:
          return 'text'
      }
    },
    fieldsTitle () {
      return this.fields.map(field => field.label)
    }
  },
  watch: {
    fieldSelected (newValue) {
      if (newValue) {
        this.value = this.fields.find(field => field.label === newValue).value
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    save () {
      let value = this.value
      const field = this.fields.find(field => field.label === this.fieldSelected).field

      if (!field) {
        this.$buefy.toast.open({
          message: 'O campo não pode ser vazio',
          type: 'is-danger'
        })
        return
      }

      if (this.fieldType === 'select') {
        value = value === 'Verdadeiro'
      } else if (this.fieldType === 'number') {
        value = parseInt(value)
      } else {
        value = value.toString()
        if (value.length === 0) {
          this.$buefy.toast.open({
            message: 'O campo não pode ser vazio',
            type: 'is-danger'
          })
          return
        }
      }
      this.$emit('save', field, value)
    }
  }
}
</script>
