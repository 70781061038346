<template>

  <div class="dropdown-item-tag" @click="onClick">
    <slot />
  </div>

</template>

<script>
export default {
  name: 'DropdownItemTag',
  methods: {
    onClick (e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style>

.dropdown-item-tag:hover {
  cursor: pointer;
}

.dropdown-item-tag:hover {
  background-color: rgb(247, 244, 244);
}

</style>
