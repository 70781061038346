<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p v-if="tagUpdating" class="modal-card-title">
              Atualizar Tag
            </p>
            <p v-else class="modal-card-title">Inserir Tag</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important">
            <b-field label="Título">
              <b-input
                type="text"
                v-model="title"
                placeholder="Título"
                required
              >
              </b-input>
            </b-field>

            <b-field label="Adicionar contato">
              <b-autocomplete
                class="min-300"
                v-model="selectedContact"
                placeholder="Digite o nome do contato"
                :keep-first="false"
                :open-on-focus="true"
                :data="contacts"
                field="nameAndPhone"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="option => (contactSelected = option)"
                :clearable="true"
                expanded
              >
              </b-autocomplete>
              <button
                class="button is-primary"
                type="button"
                :disabled="!selectedContact"
                @click="selectContact"
              >
                <b-icon icon="account-plus" size="" />
              </button>
            </b-field>

            <b-field label="Contatos selecionados">
              <div>
                <div v-if="selectedContacts.length > 0">
                  <b-table
                    :data="selectedContacts"
                    :bordered="true"
                    :striped="false"
                    :narrowed="false"
                    :hoverable="false"
                    :loading="false"
                    :focusable="false"
                    :mobile-cards="true"
                    paginated
                    :per-page="5"
                    pagination-position="bottom"
                  >
                    <b-table-column
                      :numeric="true"
                      field="id"
                      label="ID"
                      v-slot="props"
                    >
                      {{ props.row.id }}
                    </b-table-column>

                    <b-table-column field="name" searchable label="Nome" v-slot="props">
                      {{ props.row.name }}
                    </b-table-column>
                    <b-table-column
                      field="delete"
                      width="200"
                      label="Remover"
                      v-slot="props"
                    >
                      <b-button
                        type="is-danger"
                        @click="removeContact(props.row)"
                        >Remover</b-button
                      >
                    </b-table-column>
                  </b-table>
                  <b-button
                    class="button is-danger"
                    type="button"
                    @click="removeAllContacts"
                    expanded
                  >
                    Remover todos
                  </b-button>
                </div>
                <div v-else>
                  <p>Não há contatos selecinados</p>
                </div>
              </div>
            </b-field>

            <div class="field mt-4">
              <b-checkbox v-model="enabled">Tag ativa</b-checkbox>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button
              v-if="tagUpdating"
              class="button is-primary"
              @click="updateTag"
            >
              Atualizar
            </button>
            <button v-else class="button is-primary" @click="addTag">
              Gravar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'
import debounce from 'debounce'
import { mapActions } from 'vuex'
export default {
  name: 'ModalInsertTag',
  components: {
    multiselect
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    tagUpdating: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      title: '',
      selectedContacts: [],
      selectedContact: '',
      contacts: [],
      enabled: true,
      isFetching: false,
      contactSelected: null
    }
  },
  computed: {
    getContacts () {
      return this.contacts.map((contact) => {
        const c = contact
        c.code = c.id
        c.name = `${c.name} - (${c.phone})`
        return c
      })
    }
  },
  created () {
    this.loadDataAsync()

    if (this.tagUpdating) {
      this.title = this.tagUpdating.title
      this.enabled = this.tagUpdating.enabled

      this.selectedContacts = this.tagUpdating.contacts_tags.map(
        (tagContact) => {
          const c = tagContact.contact
          c.name = `${c.name} - (${c.phone})`
          return c
        }
      )
    }
  },
  methods: {
    ...mapActions(['fetchContactsWithFilter']),
    removeAllContacts () {
      this.selectedContacts = []
    },
    getAsyncData: debounce(function (name) {
      this.contacts = []
      if (!name.length) {
        return
      }
      this.isFetching = true
      this.fetchContactsWithFilter({ limit: 5, filter: name })
        .then(({ data: { results } }) => {
          results.forEach(item =>
            this.contacts.push({
              ...item,
              nameAndPhone: `${item.name} - ${item.phone}`
            })
          )
        })
        .catch(error => {
          this.contacts = []
          throw error
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
    removeContact (contact) {
      this.selectedContacts = this.selectedContacts.filter(c => c.id !== contact.id)
    },
    selectContact () {
      if (this.selectedContacts.find(contact => contact.id === this.contactSelected.id)) return
      this.selectedContacts.unshift(this.contacts.find(contact => contact.id === this.contactSelected.id))
    },
    async updateTag () {
      if (this.title === '') {
        this.$buefy.dialog.alert({
          title: 'Insira o Título da Tag',
          message: 'Insira o título da tag antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        const contactsIds = this.selectedContacts.map(c => c.id)
        this.emitUpdateTag({
          id: this.tagUpdating.id,
          title: this.title,
          enabled: this.enabled,
          contacts: contactsIds
        })
      }
    },
    emitUpdateTag (tag) {
      this.$emit('updateTag', tag)
    },
    async loadDataAsync () {
      if (this.tagUpdating) {
        this.title = this.tagUpdating.title
        this.enabled = this.tagUpdating.enabled
      }
    },
    async addTag () {
      if (this.title === '') {
        this.$buefy.dialog.alert({
          title: 'Insira o Título da Tag',
          message: 'Insira o título da tag antes de prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        const contactsIds = this.selectedContacts.map(c => c.id)
        this.$emit('addTag', {
          title: this.title,
          enabled: this.enabled,
          contacts: contactsIds
        })
      }
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.emoji-picker-container {
  white-space: pre-wrap;
}
.multiselect--active {
  z-index: 999;
}
</style>
