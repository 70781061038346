<template>
  <div class="dropdown-tag dropdown">
    <b-button
      v-if="isDeleting"
      class="button mb-2 is-pulled-right"
      :loading="isDeleting"
    />
    <div v-else>
      <span
      @click="toggle()">
        <b-icon
          class="vac-svg-button vac-col-messages dropdown-icon"
          role="button"
          icon="dots-vertical"
        >
        </b-icon>
      </span>

      <div v-show="isOpen" class="dropdown-content-tag dropdown-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownTag',
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    isDeleting: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    toggle (e) {
      this.isOpen = !this.isOpen
    },

    close (e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.close)
  }
}
</script>

<style>

.table td[data-label=""] {
  vertical-align: middle !important;
  text-align: center !important;
}

.table-container, html {
  overflow: visible !important;
}

.dropdown-tag {
  position: relative;
  display: inline-block;
}

.dropdown-content-tag {
  position: absolute;
  width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.07);
  z-index: 1;
  right: 5%;
  margin-top: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
}

</style>

<style scoped>
.is-loading {
  border-width: 0px;
  color: black;
  background-color: transparent;
}
</style>
