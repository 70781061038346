<template>

  <span class="tag is-success active" v-if="active">
    Ativo
  </span><span class="tag inactive" v-else>
    Inativo
  </span>

</template>

<script>
export default {
  name: 'IsActiveTag',
  props: {
    active: {
      type: Boolean,
      require: true
    }
  }
}
</script>

<style>

.table td[data-label="Status"] {
  vertical-align: middle !important;
  /* text-align: center !important; */
}

.active {
  width: 54.094px;
}

.inactive {
  background-color: rgb(205, 204, 204) !important;
  color: #FFFFFF !important;
}
</style>
