<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Tags
    </hero-bar>
    <section class="section is-main-section">
        <ModalInsertTag
            v-if="isComponentModalActive"
            v-on:addTag="handleAddTag"
            v-on:closeModal="closeModal"
            :isComponentModalActive="isComponentModalActive"
            v-on:updateTag="handleUpdateTag"
            :tagUpdating="tagUpdating"
        />
        <ModalUpdateField
            v-if="isModalFieldUpdateActive"
            v-on:closeModal="closeModal"
            v-on:save="handleBulkUpdate"
            :isComponentModalActive="isModalFieldUpdateActive"
            :fields="updateFields"
        />
      <b-loading v-if="fetchingTags" :is-full-page="true" v-model="fetchingTags" :can-cancel="false"></b-loading>
      <Table
      :checkedRows="checkedRows"
      :columns="columns"
      :data="allTags"
      v-on:update:checkedRows="handleRows"
      :events="events"
      v-on:removeTag="confirmTagDelete"
      v-on:updateTag="OpenUpdateTag"
      v-on:bulkRemoveTags="handleBulkDelete"
      v-on:bulkUpdateTags="openBulkUpdateTag"
      />
      <!-- TagsList v-else :tags='allTags' v-on:removeTag="removeTag" v-on:updateTag="OpenUpdateTag" :tagDeletingId="tagDeletingId"></TagsList -->
      <FabButton v-on:addClick="handleAddClick" iconClass="fas fa-pen-fancy" title="Nova tag"/>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import FabButton from '@/components/FabButton.vue'
import ModalInsertTag from '@/components/modals/ModalInsertTag'
import { mapActions, mapGetters } from 'vuex'
import Table from '@/components/commons/Table.vue'
import ModalUpdateField from '@/components/commons/ModalUpdateField.vue'

export default {
  name: 'Tags',
  components: {
    HeroBar,
    TitleBar,
    FabButton,
    ModalInsertTag,
    Table,
    ModalUpdateField
  },
  data () {
    return {
      isComponentModalActive: false,
      tagUpdating: null,
      tagDeletingId: 0,
      checkedRows: [],
      isModalFieldUpdateActive: false,
      updateFields: [
        {
          label: 'Título',
          field: 'title',
          type: 'text',
          value: ''
        },
        {
          label: 'Ativo',
          field: 'enabled',
          type: 'boolean',
          value: false
        }
      ],
      columns: [
        {
          field: 'id',
          label: 'ID',
          numeric: true
        },
        {
          field: 'title',
          label: 'Título',
          numeric: false
        },
        {
          field: 'enabled',
          label: 'Status',
          ActiveTag: true,
          numeric: false
        },
        {
          field: 'update_delete',
          label: '',
          numeric: false,
          dropdown: true,
          dropdownItems: [
            {
              id: 1,
              label: 'Atualizar',
              value: 'updateTag',
              action: 'updateTag',
              disabled: false
            },
            {
              id: 2,
              label: 'Excluir',
              value: 'removeTag',
              action: 'removeTag',
              disabled: false
            }
          ]
        }
      ],
      events: [
        {
          id: 1,
          label: 'Atualizar',
          value: 'bulkUpdateTags',
          action: 'bulkUpdateTags',
          disabled: false
        },
        {
          id: 2,
          label: 'Excluir',
          value: 'bulkRemoveTags',
          action: 'bulkRemoveTags',
          disabled: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['allTags', 'fetchingTags']),
    titleStack () {
      return ['Cadastros', 'Tags']
    }
  },
  methods: {
    ...mapActions(['addTag', 'fetchTags', 'deleteTag', 'updateTag', 'bulkUpdateTags', 'bulkDeleteTags']),
    handleRows (rows) {
      this.checkedRows = rows
    },
    handleBulkDelete () {
      if (this.checkedRows.length === 0) {
        this.$buefy.toast.open('Selecione ao menos uma tag para excluir!')
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Excluir tags',
        message: 'Deseja <b>excluir</b> as tags selecionadas? Esta ação não poderá ser desfeita!',
        confirmText: 'Excluir Tags',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: async () => await this.sendBulkDelete()
      })
    },
    async sendBulkDelete () {
      if (this.checkedRows.length === 0) {
        this.$buefy.toast.open('Selecione ao menos uma tag para excluir!')
        return
      }
      try {
        await this.bulkDeleteTags({ tags: this.checkedRows.map(r => r.id) })
        this.$buefy.toast.open('Tags excluídas com sucesso!')
        this.checkedRows = []
      } catch (error) {
        if (error.isAxiosError) {
          if (error.response.data.tagsErrors && error.response.data.tagsErrors.length > 0) {
            this.$buefy.dialog.alert({
              title: 'Erro',
              message: error.response.data.tagsErrors.map(e => `<b>${e}</b>`).join('<br>'),
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          } else {
            this.$buefy.dialog.alert({
              title: 'Erro',
              message: 'Não é possível excluir as tags, pois elas possuem relacionamentos com contatos ou com opções de menu! Remova-as dos mesmos para excluí-las',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        } else {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: 'Ocorreu um <b>erro</b> ao excluir as tags. Recarregue a tela e tente novamente!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
      }
    },
    openBulkUpdateTag () {
      if (this.checkedRows.length === 0) {
        this.$buefy.toast.open('Selecione ao menos uma tag para atualizar!')
        return
      }
      this.isModalFieldUpdateActive = !this.isModalFieldUpdateActive
    },
    async handleBulkUpdate (field, value) {
      this.closeModal()
      await this.bulkUpdateTags({
        tags: this.checkedRows.map(r => r.id),
        field,
        value
      }).catch((error) => {
        if (error.isAxiosError) {
          if (error.response.data.tagsErrors && error.response.data.tagsErrors.length > 0) {
            this.$buefy.dialog.alert({
              title: 'Erro',
              message: error.response.data.tagsErrors.map(e => `<b>${e}</b>`).join('<br>'),
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          } else {
            this.$buefy.dialog.alert({
              title: 'Erro',
              message: 'Não foi possível atualizar as tags, pois elas possuem relacionamentos com contatos ou com opções de menu! Remova-as dos mesmos para atualizá-las',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          }
        } else {
          this.$buefy.dialog.alert({
            title: 'Erro',
            message: 'Ocorreu um <b>erro</b> ao atualizar as tags. Recarregue a tela e tente novamente!',
            type: 'is-danger',
            hasIcon: true,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
        this.fetchTags()
      }
      )
      this.checkedRows = []
    },
    handleUpdateTag (tag) {
      this.closeModal()
      this.updateTag(tag).catch(() =>
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'Não é possível desativar a tag! Provavelmente possui relacionamento com contatos ou opções de menu, remova-a dos mesmos para desativá-la',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      )
      this.tagUpdating = null
    },
    handleAddTag (tag) {
      if (this.allTags.filter(t => t.title.toLowerCase() === tag.title.toLowerCase()).length > 0) {
        this.$buefy.dialog.alert({
          title: 'Tag já cadastrada',
          message: `Já existe uma tag <b>${tag.title}</b> cadastrada!`,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      this.closeModal()
      this.addTag(tag).catch(() => {
        this.$buefy.dialog.alert({
          title: 'Erro',
          message: 'Ocorreu um <b>erro</b> ao inserir a tag. Recarregue a tela e tente novamente!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
      this.tagUpdating = null
    },
    OpenUpdateTag (tag) {
      this.tagUpdating = tag
      this.handleAddClick()
    },
    handleAddClick () {
      this.isComponentModalActive = !this.isComponentModalActive
    },
    confirmTagDelete (tag) {
      this.$buefy.dialog.confirm({
        title: 'Excluir tag',
        message: 'Deseja <b>excluir</b> esta tag?',
        confirmText: 'Excluir Tag',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.removeTag(tag)
      })
    },
    async removeTag (tag) {
      try {
        this.tagDeletingId = tag.id
        await this.deleteTag(tag.id)
        this.$buefy.toast.open('Tag excluida com sucesso!')
      } catch (error) {
        this.$buefy.toast.open(
          'Não é possível excluir esta tag, pois ela possui relacionamentos com contatos ou com opções de menu!'
        )
      } finally {
        this.tagDeletingId = 0
      }
    },
    closeModal () {
      this.tagUpdating = null
      this.isComponentModalActive = false
      this.isModalFieldUpdateActive = false
    }
  },
  created () {
    this.fetchTags()
  }
}
</script>
